<template>
  <div>
    <h1 class="tab-header">
      <span>
        Инкассация
      </span>
      <a v-on:click.prevent="now = true">Новая</a>
    </h1>

    <div>
      <Report ref="report" url="collection/report" :def-params="{order: {field: 'collection_id', order: 'desc'}, filters: {collector_id: 'eq|' + account_id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <div v-if="error">
          {{error}}
        </div>
        <div v-else>

          <div v-if="report" class="card-report">
            <div v-if="report.rows_count === 0">
              Не найдено.
            </div>

            <template v-else>
              <div
                  class="item"
                  v-for="(row, index) in report.rows"
                  v-bind:key="index"
              >
                <h3>
                  № {{row.collection_id}} от {{convertDate(row.collection_date)}}
                </h3>

                <div>
                  Сумма: <b>{{formatRUB(row.collection_amount)}}</b>
                </div>
                <div>
                  Кассир: <b>{{row.account_title}}</b>
                </div>



              </div>

            </template>
          </div>

          <Pager />
        </div>
      </Report>
    </div>


    <div class="window" v-if="now">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="back()"></i>
          Новая инкассация
        </h1>
      </div>
      <div class="window-form">

        <div class="steps" :data-step="collectStep" :data-title="collectStepTitle[collectStep]">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>

        <template v-if="collectStep === 1">
          <Input name="user_phone" text="Введите код" required="true" v-model="bar" />
          <button v-if="bar && bar.split('').length > 7" class="btn btn-primary mb14" v-on:click="getCollect(bar)">Продолжить</button>
          <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        </template>

        <template v-if="collectStep === 2">

          <template v-if="data && data.account_id">

            <div class="input mb14">
              <label class="input-placeholder">Сумма</label>
              <div>
                {{ formatRUB( data.collection_amount ) }}
              </div>
            </div>

          </template>

        </template>

      </div>

      <div class="form-submit">
        <button class="btn btn-primary" v-if="collectStep === 2" v-on:click.prevent="collectStep === 2 ? save() : getCollect()">{{collectStep === 2 ? 'Информация верна' : 'Продолжить'}}</button>
        <a class="btn btn-secondary" v-on:click.prevent="data = {};now = false;collectStep = 1">Отменить</a>
      </div>
    </div>

    <Nav />

  </div>
</template>

<script>

import mixins from '../../../helpers/mixins.js';

import Nav from "@/pages/collector/nav";
import User from '../../../user';
import Ajax from "@/helpers/ajax";

import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';

import { StreamBarcodeReader } from "vue-barcode-reader";
import Input from "@/components/Input.vue";

export default {
  mixins: [mixins],

  components: {
    Input,
    Nav,
    Report,
    Pager,
    StreamBarcodeReader
  },

  data() {
    return {
      bar: null,
      account_id: User.currentUser.account.account_id || 0,
      now: false,
      data: {
        account_id: null,
        collection_amount: null
      },


      collectStep: 1,

      collectStepTitle: [
        '',
        'Поиск заявки',
        'Информация о заявке',
      ],
      error: null,
      report: null
    }
  },

  methods: {

    back() {
      if (this.collectStep === 1) {
        this.data = false;
        this.bar = null
      } else {
        this.collectStep--
      }
    },


    async getCollect(barcode) {
      let t = barcode.split('-')
      this.data = {
        account_id: t[0],
        collection_amount: parseFloat(t[1])*10*2
      }
      this.collectStep = 2;
    },

    async save() {
      let list = []

      list.push(Ajax.post(`/collection/save/0`, this.data));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
      } else {
        this.$toast.success({content: `Информация сохранена`});
        this.collectStep = 1;

        this.data = {
          account_id: false,
          collection_amount: 0
        }
        this.bar = null
      }
    },

    onDecode(barcode) {
      this.showCheckin(barcode)
    },

    onLoaded() {
      console.log('onLoaded')
    },

    showCheckin(barcode) {
      this.collectStep = 1;
      this.getCollect(barcode)
    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

  }
}
</script>
