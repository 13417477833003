import { createWebHistory, createRouter } from "vue-router";
import user from "./user";
import Error404 from "./pages/Error404";
import Login from "./pages/auth/Login";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Account from "./pages/auth/Account";


//Collector

import CollectorNow from "./pages/collector/now/Index";


//Cashier


import CashierCash from "./pages/cashier/finance/Cash";

import CashierEx from "./pages/cashier/ex/Index";
import CashierTicket from "./pages/cashier/ticket/Index";

import Salary from "./pages/cashier/salary/Index";

import CashierFinanceEx from "./pages/cashier/finance/report/Ex";
import CashierFinanceTi from "./pages/cashier/finance/report/Ticket";

import CashierLanding from "./pages/cashier/landing/Index";
import CashierProfile from "./pages/cashier/profile/Index";

import CashierOff from "./pages/cashier/off";

// Guide
import GuideEx from "./pages/guide/ex/Index";
import GuideRollback from "./pages/guide/rollback/Index";

export default async function() {
    await user.loadCurrentUser();

    const router = createRouter({
        history: createWebHistory(),
        base: __dirname,
        routes: [

            /* Collector */
            { path: '/collector/now', component: CollectorNow },

            /* Cashier */
            { path: '/cashier/ex', component: CashierEx },
            { path: '/cashier/cash', component: CashierCash },

            { path: '/cashier/ticket', component: CashierTicket },
            { path: '/cashier/finance/ex/', component: CashierFinanceEx },
            { path: '/cashier/finance/ticket/', component: CashierFinanceTi },

            { path: '/cashier/salary', component: Salary },

            { path: '/cashier/landing', component: CashierLanding },
            { path: '/cashier/profile', component: CashierProfile },
            { path: '/cashier/off', component: CashierOff },

            /* Guide */

            { path: '/guide/ex', component: GuideEx },
            { path: '/guide/rollback', component: GuideRollback },

            /* Account and other */
            { path: '/auth/login', component: Login },
            { path: '/auth/forgot', component: Forgot },
            { path: '/auth/reset', component: Reset },
            { path: '/setting/account', component: Account },

            { path: '/logout',
                beforeEnter (to, from, next) {
                    user.logout();
                    next('/auth/login');
                }
            },

            { path: '/', component: Error404,
                beforeEnter(to, from, next) {
                    next(user.getHomepage());
                }
            }

        ]
    });

    router.beforeEach((to, from, next) => {

        window.scrollTo(0, 0)

        if ((to.path.indexOf('/auth/') === 0) && (user.isLoggedIn())) {
            return next({path: '/'});
        }

        if ((to.path.indexOf('/auth/') === 0)) {
            return next();
        }

        if (['/logout'].indexOf(to.path) > -1) {
            user.logout()
            return next();
        }

        if (!user.isLoggedIn()) {
            next({path: '/auth/login'});
        } else {
            if (to.path.indexOf(`/docs`) === 0) {
                return next();
            }

            if (to.path.indexOf(`/${user.currentUser.user.role_type}`) === 0) {
                return next();
            }

            if ((user.currentUser.user.role_type === 'adm') && ((to.path.indexOf(`/adm`) === 0) || (to.path.indexOf(`/dev`) === 0))) {
                return next();
            }

            return next();

        }
    });

    return router;
}
