<template>
  <div>
    <h1>Профиль</h1>

    <router-link class="btn btn-primary" to="/setting/account">Настройки профиля</router-link>
   <div>
     <h3>
       План на текущую неделю
     </h3>


     <div class="counts">
       <div>
         <b>{{formatRUB(data.profile.plan ? data.profile.plan.current_excursion : 0)}} из {{formatRUB(data.profile.plan ? data.profile.plan.plan_excursion : 0)}}</b>
         <i>Экскурсии</i>
       </div>

       <div>
         <b>{{formatRUB(data.profile.plan ? data.profile.plan.current_other : 0)}} из {{formatRUB(data.profile.plan ? data.profile.plan.plan_other : 0)}}</b>
         <i>Остальные продажи</i>
       </div>
     </div>

     <h3>
        График работы
     </h3>

     <template v-if="data.profile.schedule">
       <div
           v-for="(r, i) in data.profile.schedule"
           v-bind:key="i"
           class="staff_schedule_item"
       >

         <div class="staff_schedule_item_time">
           {{convertDate(r.schedule_date, 'DD.MM.YYYY')}}
           <div>
             {{convertTime(r.schedule_time_start, r.schedule_time_end)}}
           </div>
         </div>

         <div class="staff_schedule_item_title">
           {{r.point_title}}
           <div>
             {{r.point_address}}
           </div>
         </div>

       </div>
     </template>

   </div>
    <Nav />

  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import moment from "moment";
import Nav from "@/pages/cashier/nav";

export default {
  mixins: [mixins],

  components: {
    Nav,

  },

  created() {
    this.loadPlace();
  },

  data() {
    return {
      data: {
        profile: {
          plan: {
            current_other: 0,
            plan_other: 0,
            current_excursion: 0,
            plan_excursion: 0
          },
          schedule: []
        },



        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        places: [],
      },
      days: ['', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],

      order: {
        excursion_id: null,
      },

      times: false,
      activeTime: false,
      activeDate: moment(new Date()).format('DD.MM.YYYY'),
      schedule: {
        schedule_all: [],
        calendar: [],
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
        mount: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
        schedule_types: [
          {
            id: '',
            title: 'Выбрать'
          },
          {
            id: 'all',
            title: 'Ежедневно'
          },
          {
            id: 'reply_day',
            title: 'Каждый X день недели'
          }
        ]
      },
      activeDay: 0,
      showPlace: false,
      activeEx: null,
      showDate: false,
      orderStep: 1,
      orderPhoneCount: 1,
      orderStepTitle: [
        '',
        'Общая информация',
        'Информация о клиенте',
        'Оплата и бронирование'
      ],
      moment: moment,
      error: null,
      report: null
    }
  },

  methods: {



    async loadPlace() {

      let urls = {
        'places': `/settings/place/report?filters%5Bplace_status%5D=eq%7Cactive&order%5Bfield%5D=place_id&order%5Border%5D=desc&page=1&limit=100`,
        'exs': `/excursion/list?filters%5Bexcursion_start_date%5D=lte%7C${ moment(new Date()).add(14, 'days').format('YYYY-MM-DD') }&page=1&limit=100`,
        'profile': `/profile/seller`,
      };

      this.data = await Ajax.getBulk(urls);

    },


  }
}
</script>
