<template>
  <div>
    <h1 class="tab-header">
      <router-link to="/cashier/ex">Экскурсии</router-link>
      <span>
        Билеты
      </span>
    </h1>

    <div class="ex-search">
      <Input type="text" name="search" v-model="search" placeholder="Поиск билета" />
    </div>

    <div class="dates">
      <div
          class="dates-item"
          v-for="(date, index) in dates"
          v-bind:key="index"
          v-on:click="
            activeDay = date.day;
            activeDate = date.clearDate
          "
          :class="{
            'weekends': date.weekends,
            'active': activeDay === date.day
          }"
      >
        <div class="day">
          {{date.day}}
        </div>
        <div class="day-title">
          {{date.dayTitle}}
        </div>
      </div>
    </div>


    <div class="full-width-content table-wrapper">
      <template v-if="data.exs">
        <div class="ex-cards">

          <template v-for="(row, index) in data.exs.rows">
            <div
                class="item_wrap"
                v-bind:key="index"
                v-if="(search && search.length ? row.product_title.toLowerCase().indexOf(search.toLowerCase()) > -1 : true)"
            >
              <div v-on:click="activeEx = row" class="item">

              <div class="item-wrap">
                <div>
                  <div class="title">
                    {{row.product_title}}
                  </div>
                </div>
                <div class="prices">
                  <div class="prices-item">
                    {{formatRUB(row.product_price_adult)}}
                    <div>
                      взрослый
                    </div>
                  </div>
                  <div class="prices-item">
                    {{formatRUB(row.product_price_children_free)}}
                    <div>
                      дети (0-6)
                    </div>
                  </div>
                  <div class="prices-item">
                    {{formatRUB(row.product_price_children)}}
                    <div>
                      дети (6-12)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </template>
          <div class="not-fount">
            Упс, билетов не нашлось:(
          </div>

        </div>
      </template>
    </div>

    <div class="window" v-if="activeEx">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="back()"></i>
          Новая продажа
        </h1>
        <div class="sum">
          итого:
          <b>
            {{formatRUB(
              Math.ceil(getSum(
                  order.allSum
              ))
            )}}
          </b>
        </div>
      </div>
      <div class="window-form">

          <div class="target_click" v-on:click="activeEx = false">
            <Input name="product_title" text="Наименование" required="true" v-model="activeEx.product_title" disabled="disabled" />
          </div>

          <div>

            <PeriodPicker
                name="sale_event_date"
                add-time="true"
                text="Дата мероприятия"
                @apply="(res) => {order.sale_event_date = res}"
                v-model="order.sale_event_date"
            />

          </div>

          <div class="target_click" v-on:click="showPlace = true">
            <Input name="ex_place" text="Количество" required="true" v-model="order.placeTitle" disabled="disabled" />
          </div>

      </div>
      <div class="form-submit">
        <a class="btn btn-primary" v-on:click.prevent="save()">
          Оплачено
        </a>
        <a class="btn btn-secondary" v-on:click.prevent="activeEx = null">Отменить</a>
      </div>
    </div>

    <div class="window window-level" v-if="showDate">
      <div class="window-header">
        <h1>Дата и время</h1>
        <a class="window-close" v-on:click="showDate = false"></a>
      </div>
      <div class="window-form">
        <div class="schedule">
          <div class="item" v-for="(row, index) in schedule.calendar" v-bind:key="index">
            <div class="cal-tour">
              <div class="my_cal_box">
                <div class="title">
                  <b>{{row.mount}} {{row.year}}</b>
                </div>
                <div><b>Пн</b></div>
                <div><b>Вт</b></div>
                <div><b>Ср</b></div>
                <div><b>Чт</b></div>
                <div><b>Пт</b></div>
                <div><b>Сб</b></div>
                <div><b>Вс</b></div>

                <div v-for="(row, index) in row.days" v-bind:key="index" class="time-on"
                     v-on:click="
                          activeDate = row.date;
                           showDate = false
                         "
                     :class="{active: activeDate === row.date}"
                >
                  {{row.day || ''}}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="window window-level" v-if="showPlace">
      <div class="window-header">
        <h1>Места</h1>
      </div>
      <div class="window-form">

        <div class="input-group">
          <Input name="sale_count_adult" text="Взрослые" required="true" v-model.number.trim="order.sale_count_adult" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.sale_count_adult > 0 ? order.sale_count_adult-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.sale_count_adult++">+</a>
          </div>
        </div>

        <div class="input-group">
          <Input name="sale_count_children" text="Дети 6 - 12 лет" required="true" v-model.number.trim="order.sale_count_children" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.sale_count_children > 0 ? order.sale_count_children-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.sale_count_children++">+</a>
          </div>
        </div>

        <div class="input-group">
          <Input name="sale_count_children_free" text="Дети 0 - 6 лет" required="true" v-model.number.trim="order.sale_count_children_free" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.sale_count_children_free > 0 ? order.sale_count_children_free-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.sale_count_children_free++">+</a>
          </div>
        </div>


      </div>

      <div class="form-submit">
        <a class="btn btn-primary" v-on:click.prevent="showPlace = false">Применить</a>
      </div>
    </div>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import moment from "moment";
import Input from "@/components/Input";

import Nav from "@/pages/cashier/nav";

import User from '../../../user';
import PeriodPicker from '@/components/PeriodPicker';

export default {
  mixins: [mixins],

  components: {
    Nav,
    PeriodPicker,
    Input
  },

  created() {
    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    this.loadPriceType();

    while (i < 30) {

      let d = new Date(currentDate)

      dateArray.push({
        date: d,
        clearDate: moment(d).format('YYYY-MM-DD'),
        day: moment(d).format('DD'),
        mount: moment(d).format('MM'),
        dayTitle: this.days[moment(d).isoWeekday()],
        weekends: moment(d).isoWeekday() > 5
      })

      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
  },

  mounted() {
    this.loadPlace();
  },

  watch: {

    activeDate() {
      this.activeDateTime = `${this.activeDate}г. в ${this.activeTime}`;
      this.setPlaceTitle()
    },
    activeTime() {
      this.activeDateTime = `${this.activeDate}г. в ${this.activeTime}`;
      this.setPlaceTitle()
    },

    'order.sale_count_adult' () {
      this.setPlaceTitle()
    },
    'order.sale_count_children' () {
      this.setPlaceTitle()
    },
    'order.sale_count_children_free' () {
      this.setPlaceTitle()
    },
    'activeEx' () {
      this.setPlaceTitle()
    },


    async 'order.order_phone' (e) {
      let clearPhone = e.replace(/[^0-9]/g, '')

      if (clearPhone.length === 11) {
        let result = await Ajax.get(`/accounts/find-by-phone?type=all&phone=${clearPhone}`);
        if (result.length) {
          this.order.order_client_title = result[0].account_title
          this.order.account_id = result[0].id
        } else {
          this.order.account_id = null
        }
      }

    }

  },

  data() {
    return {
      priceType: [],
      showMedia: null,
      account_id: User.currentUser.account.account_id || 0,
      search: null,
      activeDateTime: '',
      dates: [],
      data: {
        exs: [],
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        places: [],
      },
      days: ['', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
      promoData: null,
      order: {
        placeTitle: '1 взрослый',
        excursion_id: null,
        sale_count_adult: 1,
        sale_count_children: 0,
        sale_count_children_free: 0,
        childrenValues: [],
        childrenValuesPlace: [],
        childrenValuesCount1: 0,
        childrenValuesCount2: 0,
        childrenValuesCount3: 0,
        sale_event_date: this.convertDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
        order_phone: '',
        order_client_title: '',
        account_id: null,
        order_comment: '',
        allSum: 0,
        allSumPrepay: 0,
        order_sum_sale: 0,
        pay_type: 'cash',
        sale_prepay_type: 'prepay',
        sale_amount: 0,
        sale_type: 'percent'
      },

      times: false,
      activeTime: false,
      activeFlight: null,
      activeDate: moment().format('YYYY-MM-DD'),
      schedule: {
        schedule_all: [],
        calendar: [],
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
        mount: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
        schedule_types: [
          {
            id: '',
            title: 'Выбрать'
          },
          {
            id: 'all',
            title: 'Ежедневно'
          },
          {
            id: 'reply_day',
            title: 'Каждый X день недели'
          }
        ]
      },
      activeDay: moment().format('DD'),
      showPlace: false,
      activeEx: null,
      showDate: false,
      orderStep: 1,
      orderPhoneCount: 1,
      orderStepTitle: [
        '',
        'Общая информация',
        'Информация о клиенте',
        'Оплата и бронирование'
      ],
      moment: moment,
      error: null,
      report: null
    }
  },

  methods: {

    back() {
      if (this.orderStep === 1) {
        this.activeEx = null
      } else {
        this.orderStep--
      }
    },

    async loadPriceType() {
      let price_type = await Ajax.get('/misc/price_type?query=');
      if (price_type) {
        this.priceType = price_type
      }
    },

    async loadMedia(excursion_id) {

      let result = await Ajax.get(`/excursion/${excursion_id}`);
      if (result.response) {
        this.showMedia = result.response;
      }

    },

    generatePassword() {
      let length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";

      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    async save() {
      this.clearError()

      let orderData = this.order

      /*
            product_id
            sale_count
            sale_count_adult
            sale_count_children
            sale_count_children_free
            sale_price_total
            sale_price_adult
            sale_price_children
            sale_price_children_free
            sale_pay_type
            sale_seller_id
       */
      orderData.cartSum = Math.ceil(this.getSum(this.order.allSum))
      orderData.cartSumSale = this.order.order_sum_sale
      orderData.cartSumPrepay = this.order.allSumPrepay === this.order.allSum ? Math.ceil(this.getSum(this.order.allSum)) : Math.ceil(this.getSalePrepay(this.order.allSumPrepay, this.order.allSum))

      orderData.cartSumFull = Math.ceil(this.getSum(this.order.allSum))
      orderData.excursion_id = this.activeEx.excursion_id
      orderData.excursion_title = this.activeEx.excursion_title

      orderData.excursion_date = this.activeDate + ' ' + this.activeTime + ':00'


      orderData.sale_seller_id = this.account_id
      orderData.product_id = this.activeEx.product_id
      orderData.sale_count = this.order.sale_count_adult + this.order.sale_count_children + this.order.sale_count_children_free



      orderData.order_tickets = ''
      orderData.order_status = (this.order.pay_type === 'online' ? 'pending' : 'order')
      orderData.order_pay_type = this.order.pay_type

      let list = [];

      list.push(Ajax.post(`/sale/save/0`, orderData));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
        this.setError(orderResult.error);
      }

      if (orderResult.response) {

        this.activeEx = false;

        this.order = {
          account_id: null,
          order_client_title: '',
          order_phone: '',
          order_adult: 1,
          order_children: 0,
          sale_count_adult: 1,
          sale_count_children: 0,
          sale_count_children_free: 0,
          childrenValues: [],
          childrenValuesCount1: 0,
          childrenValuesCount2: 0,
          childrenValuesCount3: 0,
          childrenValuesPlace: [],
          place_data: null,
          point_data: [],
          order_place: null,
          order_point: null,
          order_comment: '',
          order_address: '',
          pay_type: 'cash'
        }

        this.$toast.success({content:`Заказ успешно создан`});
      }
    },

    async checkPromo() {
      let list = [];
      list.push(Ajax.get('/promo?query=' + this.order.order_promo + '&ex=' + this.activeEx.excursion_id));

      let [promoResult] = await Promise.all(list);

      if (promoResult) {
        this.promoData = promoResult;
        this.updateOrderPrices()
      }
    },

    updateOrderPrices(){

      if (this.activeEx && this.order) {
        this.order.allSum = (
            (this.activeEx.product_price_adult * this.order.sale_count_adult) +
            (this.activeEx.product_price_children_free * this.order.sale_count_children_free) +
            (this.activeEx.product_price_children * this.order.sale_count_children)
        )


        this.order.sale_price_total = (
            (this.activeEx.product_price_adult * this.order.sale_count_adult) +
            (this.activeEx.product_price_children_free * this.order.sale_count_children_free) +
            (this.activeEx.product_price_children * this.order.sale_count_children)
        )


        this.order.sale_price_adult = (
            (this.activeEx.product_price_adult * this.order.sale_count_adult)
        )
        this.order.sale_price_children = (
            (this.activeEx.product_price_children * this.order.sale_count_children)
        )
        this.order.sale_price_children_free = (
            (this.activeEx.product_price_children_free * this.order.sale_count_children_free)
        )

        this.order.order_sum_sale = Math.floor(this.getSaleSum(this.order.allSum))

      }

    },

    setChildrenCount() {
      this.order.childrenValuesCount1 = 0;
      this.order.childrenValuesCount2 = 0;
      this.order.childrenValuesCount3 = 0;

      if (this.order.childrenValues) {
        this.order.childrenValues.find(e => { if (e <= 5) {this.order.childrenValuesCount1++} });
        this.order.childrenValues.find(e => { if (e > 5) {this.order.childrenValuesCount2++} });
      }

      this.order.childrenValuesPlace.find(e => { if (e) {this.order.childrenValuesCount3++;this.order.childrenValuesCount1--} });
    },

    getSale(sum, prepay = 0) {

      if (this.activeEx.excursion_sale_amount > 0) {
        if (this.activeEx.excursion_sale_type === 'percent') {
          if (this.activeEx.excursion_sale_prepay_type === 'all') {
            return sum - (sum / 100 * this.activeEx.excursion_sale_amount)
          }
          if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
            return sum - (sum / 100 * this.activeEx.excursion_sale_amount)
          }
          if (this.activeEx.excursion_sale_prepay_type === 'other') {
            return sum - ((sum - prepay) / 100 * this.activeEx.excursion_sale_amount)
          }
        } else {
          return sum - this.activeEx.excursion_sale_amount
        }
      } else {
        return sum
      }
    },

    getSaleSum(sum, prepay = 0) {

      if (this.order.sale_amount > 0) {
        this.promoData = [{
          promo_sale: this.order.sale_amount,
          promo_summed: 'off',
          promo_type: this.order.sale_type,
          promo_type2: this.order.sale_prepay_type
        }]
      }

      if (this.activeEx) {
        if (this.promoData && this.promoData.length) {
          if (this.promoData[0].promo_type2 === 2 && this.promoData[0].promo_summed === 'off') {
            return this.order.allSumPrepay - this.getSalePrepay(this.order.allSumPrepay)
          }
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.activeEx.excursion_sale_amount > 0) {
                if (this.activeEx.excursion_sale_type === 'percent') {
                  if (this.activeEx.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.activeEx.excursion_sale_amount + this.promoData[0].promo_sale))
                  }
                } else {
                  return (sum / 100 * this.promoData[0].promo_sale) + this.activeEx.excursion_sale_amount
                }
              } else {
                sum = sum / 100 * this.promoData[0].promo_sale
              }
            } else {
              sum = sum / 100 * this.promoData[0].promo_sale
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.activeEx.excursion_sale_amount > 0) {
                if (this.activeEx.excursion_sale_type === 'percent') {
                  if (this.activeEx.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.activeEx.excursion_sale_amount) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                  if (this.activeEx.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.activeEx.excursion_sale_amount)) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                } else {
                  return (this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)) + this.activeEx.excursion_sale_amount
                }
              } else {
                sum = this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
              }

            } else {
              sum = this.promoData[0].promo_sale
            }
          }
        } else {

          if (this.activeEx.excursion_sale_amount > 0) {
            if (this.activeEx.excursion_sale_type === 'percent') {
              if (this.activeEx.excursion_sale_prepay_type === 'all') {
                return sum / 100 * this.activeEx.excursion_sale_amount
              }
              if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
                return sum / 100 * this.activeEx.excursion_sale_amount
              }
              if (this.activeEx.excursion_sale_prepay_type === 'other') {
                return ((sum - prepay) / 100 * this.activeEx.excursion_sale_amount)
              }
            } else {
              return this.activeEx.excursion_sale_amount
            }
          } else {
            return 0
          }

        }
      }

      return sum
    },

    getSalePrepay(sum, price = 0) {

      let promoSale = 0;

      if (this.promoData && this.promoData.length) {
        if (this.promoData[0].promo_type2 === 2) {
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = (this.order.allSum / 100 * this.promoData[0].promo_sale)
            } else {
              return sum - (this.order.allSum / 100 * this.promoData[0].promo_sale)
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
            } else {
              return sum - this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
            }
          }
        }
      }

      if (this.activeEx.excursion_sale_amount > 0) {
        if (this.activeEx.excursion_sale_type === 'percent') {
          if (this.activeEx.excursion_sale_prepay_type === 'all') {
            return sum - promoSale
          }
          if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
            return sum - (price / 100 * this.activeEx.excursion_sale_amount) - promoSale
          }
          if (this.activeEx.excursion_sale_prepay_type === 'other') {
            return sum - promoSale
          }
        } else {
          if (this.activeEx.excursion_sale_prepay_type === 'prepay') {
            return sum - this.activeEx.excursion_sale_amount - promoSale
          } else {
            return sum - promoSale
          }
        }
      } else {
        return sum - promoSale
      }
    },

    getSum(sum) {
      return Math.floor(this.order.allSum - this.getSaleSum(sum))
    },


    setPlaceTitle() {
      this.order.placeTitle = `${this.order.sale_count_adult} ${this.declination(this.order.sale_count_adult, ['взрослый', 'взрослых', 'взрослых'])}`;

      if ((this.order.sale_count_children + this.order.sale_count_children_free)) {
        this.order.placeTitle = `${this.order.placeTitle}, ${this.order.sale_count_children + this.order.sale_count_children_free} ${this.declination(this.order.sale_count_children + this.order.sale_count_children_free, ['ребёнок', 'ребёнка', 'детей'])}`;
      }

      this.setChildrenCount()
      this.updateOrderPrices()
    },

    createCalendar() {

      this.schedule.calendar = [];


      let sm = parseInt(moment(new Date()).format('M'));
      let em = parseInt(moment(new Date()).add(3, 'months').format('M'));
      let y = parseInt(moment(new Date()).format('YYYY'));

      for (let i = 0; i <= (em - sm); i++) {
        let mount = sm + i;
        let mountDate = moment().day(-1).month(mount - 1);
        let days = [];
        let countDays = mountDate.daysInMonth();
        let firstDay = mountDate.isoWeekday()

        for (let i = 0; i <= countDays + firstDay; i++) {

          let day = i - firstDay;

          let date = y + '-' + (mount < 10 ? '0' + mount : mount) + '-' + (day < 10 ? '0' + day : day);
          let timeData = Object.entries(this.activeEx.dates).filter(i => i[0].indexOf(date) > -1)

          if (timeData && timeData.length) {
            console.log(timeData)
          }

          days[i-1] = {
            day: day > 0 ? day : false,
            date: date,
            mount: mount,
            weekend: (mountDate.day(i).isoWeekday() > 5),
            times: timeData
          }
        }

        this.schedule.calendar[i] = {
          mount: this.schedule.mount[mount - 1],
          year: y,
          days: days
        }
      }

    },

    async loadPlace() {

      let urls = {
       'exs': `/settings/product/report`,
      };

      this.data = await Ajax.getBulk(urls);

    },
  }
}
</script>
