<template>
  <div>

      <h1>Мои продажи</h1>

      <div>
        <div class="tab">
          <router-link to="/cashier/finance/ex/" class="active">Экскурсии</router-link>
          <router-link to="/cashier/finance/ticket/">Билеты</router-link>
        </div>

      </div>

    <Report ref="report" url="order/report" :def-params="{order: {field: 'order_id', order: 'desc'}, filters: {order_seller: 'eq|' + user.account_id }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

          <div class="card-report">

            <div class="card-report_filter">
              <InputFilter name="order_id" placeholder="Номер заказа" />
              <InputFilter name="order_title" placeholder="Название экскурсии" filter-type="like" />

              <div class="input-group">
                <PeriodPickerFilter placeholder="Дата отправления" :allow-future="true" name="order_date_ot" />
                <PeriodPickerFilter placeholder="Дата продажи" name="order_date" />
              </div>
              <InputFilter name="order_client_title" placeholder="ФИО" filter-type="like" />
              <InputFilter name="order_client_phone" placeholder="Телефон" filter-type="like" />
              <SelectFilter name="order_status" :options="[['order', 'Оплачен'], ['waiting_for_capture', 'Оплачен, не подтверждён'], ['reserved', 'Бронь'], ['cancel', 'Отменён'], ['cancel_success', 'Возврат'], ['cancel_refund', 'Частичный возврат']]" track-by="0" label="1" />

            </div>

            <div v-if="report">
              <div v-if="report.rows_count === 0">
                  Не найдено.
              </div>

              <template v-else>
                <div
                    class="item"
                    :class="{
                      warning: row.order_partial_pay === 1 && row.order_pay_sum != row.order_partial_sum
                    }"
                    v-on:click="getOrder(row.order_id)" v-for="(row, index) in report.rows"
                    v-bind:key="index"
                >
                  <h3>
                    № {{row.order_id}} от {{convertDate(row.order_date, 'DD.MM HH:mm')}}
                  </h3>

                  <div>
                    <b>{{row.order_title}}</b>
                  </div>

                  <div>
                    отправление: <b>{{convertDate(row.order_date_ot, 'DD.MM.YYYY HH:mm')}}</b>
                  </div>

                  <div v-if="row.order_status === 'order'">
                    оплачено:
                    <b>{{formatRUB(row.order_pay_sum === row.order_partial_sum || !row.order_partial_pay ? row.order_pay_sum : row.order_partial_sum)}}</b>
                    из
                    {{formatRUB(row.order_sum_all)}} - <b> {{ payTypes[row.order_pay_type] }} </b>
                  </div>
                  <div v-else>
                    оплачено:
                    <b>{{formatRUB(row.order_pay_sum === row.order_partial_sum || !row.order_partial_pay ? row.order_pay_sum : row.order_partial_sum)}}</b>
                    из
                    {{formatRUB(row.order_sum_all)}} - <b> {{ payTypes[row.order_pay_type] }} </b>
                  </div>

                  <div
                      v-if="row.order_partial_pay && row.order_pay_sum != row.order_partial_sum"
                      class="status"
                      v-bind:class="`status_paused`"
                  >
                    Ожидание оплаты
                  </div>
                  <div
                      v-else
                      class="status"
                      v-bind:class="`status_${row.order_status}`"
                  >
                    {{status[row.order_status]}}
                  </div>

                </div>

              </template>
            </div>
          </div>


        <Pager />
      </div>
    </Report>

    <div class="window" v-if="showOrder">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="showOrder = null"></i>
          Заказ № {{data.order.order_id}} от {{convertDate(data.order.order_date, 'DD.MM.YYYY HH:mm')}}
        </h1>
        <div class="status" v-bind:class="`status_${data.order.order_status}`">
          {{status[data.order.order_status]}}
        </div>
      </div>

      <div class="window-form">

        <div class="values">
          <div>
            ФИО
          </div>
          <div>
            {{data.order.account_title}}
          </div>
        </div>
        <div class="values">
          <div>
            Телефон
          </div>
          <div>
            {{formatPhone(data.order.user_phone)}}
          </div>
        </div>


        <div class="values">
          <div>
            Экскурсия
          </div>
          <div>
            {{data.order.order_title}}
          </div>
        </div>

        <div class="values">
          <div>
            Дата и время отправления
          </div>
          <div>
            {{convertDate(data.order.order_date_ot, 'DD.MM.YYYY HH:mm')}}
          </div>
        </div>

        <div class="values">
          <div>
            Место посадки
          </div>
          <div>
              <AutosuggestMulti
                  v-if="data.order.place_data"
                  :multiple="false"
                  ref="excursion_place"
                  v-model="data.order.place_data_set"
                  :value="data.order.place_data_set[0]"
                  name="excursion_place"
                  text=""
                  :url="'/misc/place?ids=' + data.order.place_data.map(i => {return i.value}).join(',')"
                  v-on:input="changePlaceIds"
              />
          </div>
        </div>

        <div class="values" v-if="86400000 > (new Date().getTime() - new Date(data.order.order_date).getTime())">
          <div>
            <Select
                text="Способ оплаты"
                class="mb-0"
                id="pay_type"
                name="pay_type"
                required="true"
                :options="[['cash', 'Наличными'], ['card', 'Картой'], ['online', 'Онлайн']]"
                track-by="0"
                label="1"
                v-model="data.order.order_pay_type"
            />
          </div>
        </div>

        <div class="values" v-if="data.order.order_partial_sum !== data.order.order_pay_sum && data.order.order_partial_pay && data.order.order_pay_type !== 'online'">
          <div class="mb14">
            Оплата частями (оплачено <b>{{formatRUB(data.order.order_partial_sum)}} из {{formatRUB(data.order.order_pay_sum)}}</b>)
          </div>
          <div>
            <div class="checkbox mb14">
              <label>
                <input
                    type="checkbox"
                    name="order_partial_sum"
                    value="true"
                    v-model="data.order.is_partial"
                >
                <span>Клиент оплатил всю сумму</span>
              </label>
            </div>
          </div>
        </div>

        <div class="values" v-if="data.order.order_partial_sum !== data.order.order_pay_sum && data.order.order_partial_pay && data.order.order_pay_type === 'online'">
          <div class="mb14">
            Оплата частями (оплачено <b>{{formatRUB(data.order.order_partial_sum)}} из {{formatRUB(data.order.order_pay_sum)}}</b>)
          </div>
          <div>
            Измените способ оплаты, если клиент оплатил заказ вам.
          </div>
        </div>


        <button class="btn btn-primary mb14" v-on:click.prevent="saveOrder">Сохранить</button>

        <div class="values">
          <div>
            Места
          </div>
          <div v-if="data.order.transport_place_title && data.order.transport_place_title.length">
            {{ (data.order.order_transfer || data.order.order_tickets).split(',').map(i => {return data.order.transport_place_title[parseInt(i)]}).join(',') }}
          </div>
          <div v-else>
            {{data.order.order_transfer || data.order.order_tickets }}
          </div>
        </div>

        <div class="values">
          <div>
            Время посадки
          </div>
          <div>
            {{ data.order.order_place_time || '-'}}
          </div>
        </div>
      </div>

    </div>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import Select from '../../../../components/Select';


import PeriodPickerFilter from '../../../../components/report/PeriodPickerFilter';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';

import AutosuggestMulti from "../../../../components/AutosuggestMulti";

import moment from "moment";

import User from '../../../../user';
import Nav from "@/pages/cashier/nav";

export default {
  mixins: [mixins],

  components: {
    Report,
    Pager,
    PeriodPickerFilter,
    InputFilter,
    Select,
    AutosuggestMulti,
    SelectFilter,
    Nav
  },

  created() {

  },

  data() {
    return {
      payTypes: {
        online: 'Онлайн',
        card: 'Картой',
        cash: 'Наличными'
      },
      showOrder: null,
      user: User.currentUser.user || {},
      data: {
        order: {
          is_partial: false,
          place_data_set: null,
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты',
        'cancel_refund': 'Частичный возврат'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async saveOrder() {
      let data = {
        order_pay_type: this.data.order.order_pay_type,
        order_title: this.data.order.order_title,
        order_id: this.data.order.order_id,
        order_pay_sum: this.data.order.order_pay_sum,
        order_comment: this.data.order.order_comment,
        order_place: this.data.order.order_place,
        order_place_time: this.data.order.order_place_time,
        order_client_phone: this.data.order.order_client_phone,
        order_client_title:  this.data.order.order_client_title,
      };


      if (this.data.order.is_partial && this.data.order.order_pay_type !== 'online') {
        data.order_partial_sum = this.data.order.order_pay_sum
        data.order_partial_pay = 'true';
      }



      if (this.data.order.order_pay_type_old !== this.data.order.order_pay_type && this.data.order.order_pay_type === 'online') {
        if (['cancel', 'cancel_success', 'cancel_refund'].indexOf(data.order_status) === -1) {
          data.order_status = 'pending';
        }
      } else {
        if (['cancel', 'cancel_success', 'cancel_refund'].indexOf(data.order_status) === -1) {
          data.order_status = 'order';
          console.log('2')
          if ( this.data.order.order_pay_type === 'online' &&  this.data.order.order_status === 'pending') {
            data.order_status = 'pending';
          }
        }


      }

      let list = [];
      list.push(Ajax.post(`/order/save_staff/${this.data.order.order_id || '0'}`, data));

      let [userResult] = await Promise.all(list);

      if (userResult.error) {
        this.setError(userResult.error);
      }

      if (userResult.response) {
          this.$toast.success({content:`Заказ №${this.data.order.order_id} обновлён.`});
      }

      window.location.reload()
    },

    async getOrder(order_id) {

      let urls = {
        'order': `/order/${order_id}`,
      };


      this.data = await Ajax.getBulk(urls);

      this.data.order.order_pay_type_old = this.data.order.order_pay_type

      if (this.data.order.order_place) {
        if (!this.data.order.order_place_time) {
          this.changePlaceIds({value: this.data.order.order_place})
        }
      } else {
        this.data.order.order_place_time = this.data.order.order_place_time ? this.data.order.order_place_time : moment(this.data.order.order_date_ot).format('HH:mm')
      }

      this.data.order.order_date_ot_b = this.data.order.order_date_ot

      this.data.order.order_date_ot = moment(this.data.order.order_date_ot).format('YYYY-MM-DD HH:mm')

      this.data.order.order_client_title = this.data.order.order_client_title || this.data.order.account_title
      this.data.order.order_client_phone = this.data.order.order_client_phone || this.data.order.user_phone
      this.data.order.order_client_mail = this.data.order.order_client_mail || this.data.order.user_login
      this.data.order.is_partial = false;

      if (this.data.order.tickets) {
        // this.data.order.tickets.push(1, 2)
      } else {
        this.data.order.tickets = []
      }

      this.showOrder = true;

    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    changePlaceIds(val) {
      this.data.order.order_place = val.value;

      if (this.data.order.place_time && this.data.order.place_time['t' + val.value]) {
        let i = this.data.order.place_time['t' + val.value];
        let time = 0
        if (i.indexOf('-') > -1) {
          time = moment(this.data.order.order_date_ot).subtract(i.replace('-', ''), 'minutes').format('HH:mm')
        } else {
          time = moment(this.data.order.order_date_ot).add(i, 'minutes').format('HH:mm')
        }

        this.data.order.order_place_time = time

      }
    },


  }
}
</script>
