<template>
  <div>
    <h1 class="tab-header">
      <span>
        Мои рейсы
      </span>
      <a v-on:click.prevent="checkin.new = true;checkin.order_id = null;">Посадка</a>
    </h1>

    <div>


      <div class="radio mb14">
        <label>
          <input type="checkbox" name="show_arh" v-model="arh">
          <span></span>
          <div>Показать архивные рейсы</div>
        </label>
      </div>

      <div class="card-report" v-if="data.flight && data.flight.length">

        <template v-for="(row, index) in data.flight" >

          <div class="item" v-bind:key="index" v-if="arh || new Date(convertDate(row.schedule_date, 'YYYY-MM-DD HH:mm:00')).getTime() + (row.excursion_duration*1000) > new Date().getTime()">

            <h3 v-on:click="loadFlightInfo(row.flight_id, false, row.sticky_flight)" class="card_header flex-space_between d-flex">
              Рейс № {{ row.flight_id }} {{row.sticky_flight.length ? '+'+row.sticky_flight.length : ''}} <span>{{ convertDate(row.schedule_date) }}</span>
            </h3>
            <b>
              {{ row.excursion_title }}
              <template v-if="row.sticky_flight.length">
              - {{row.cnt_total_seats_save }} {{declination(row.cnt_total_seats_save, ['мест', 'места', 'мест']) }}
              </template>
            </b><br>

            <div class="mb14" v-if="row.sticky_flight.length">
              <div v-for="(st, key) in row.sticky_flight" v-bind:key="key">
                <b>
                  {{ st.excursion_title }} - {{st.cnt_total_seats }} {{declination(row.cnt_total_seats, ['мест', 'места', 'мест']) }}
                </b>
              </div>
            </div>

            <div>
              Транспорт: <b>{{ row.transport_title || 'ещё не назначен' }}</b>
            </div>
            <div class="total-count">
              <b :class="{green: row.cnt_checkin_seats === row.cnt_total_seats}">
                {{declination(row.cnt_checkin_people, ['Сел', 'Сели', 'Сели']) }} {{ row.cnt_checkin_people }} из {{ row.cnt_total_people }} чел. / {{ row.cnt_checkin_seats }} из {{ row.cnt_total_seats }} мест
              </b>

            </div>

            <template v-if="showFlight[row.flight_id] && showFlight[row.flight_id].show">

              <div class="place-list">
                <div class="p-item" v-for="(place, i) in showFlight[row.flight_id].response" v-bind:key="i">
                  <div class="header" v-on:click="showFlight[row.flight_id].response[i].show = !showFlight[row.flight_id].response[i].show">
                    <div class="title">
                      {{ place.order_place_time }} | {{ place.place_title || '-' }}
                    </div>
                    <div class="time" v-if="place.order_data">
                      <i :class="{green: place.order_data && place.order_data.filter(i => i.order_checkin_adult > 0).length === place.order_data.length}">{{ place.order_data.filter(i => i.order_checkin_adult > 0).length }} / {{ place.order_data.length }}</i>
                      <i :class="{green: place.order_data && place.order_data.filter(i => i.order_checkin_adult > 0).length === place.order_data.length}">{{ place.cnt_adult + place.cnt_children_1 + place.cnt_children_2 + place.cnt_children_free }} чел.</i>
                    </div>
                  </div>
                  <div class="order-list" v-if="showFlight[row.flight_id].response[i].show">
                    <div
                        class="ol-item"
                        v-for="(order, order_index) in place.order_data"
                        v-bind:key="order_index"
                        :class="{
                          checkin: order.order_checkin_adult && (parseInt(order.order_checkin_children) + parseInt(order.order_checkin_adult)) > 0,
                          checkin_not_full: order.order_checkin_adult && order.order_checkin_adult > 0 && (
                              (parseInt(order.order_checkin_adult) + parseInt(order.order_checkin_children) + parseInt(order.order_checkin_children_free)) !==
                              (parseInt(order.order_adult) + parseInt(order.order_children_1) + parseInt(order.order_children_2)  + parseInt(order.order_children_free))
                          ),
                      }">
                      <div class="ol-name">
                      <span v-on:click="showCheckin(order.order_id, false)">
                        <i v-if="order.order_read_it"></i>
                        №{{ order.order_id }} - {{ order.order_client_title }}<br>
                        {{ order.order_title }}
                      </span>
                        <a :href="`tel:${order.order_client_phone}`">
                          {{ formatPhone(order.order_client_phone) }}
                        </a>
                      </div>
                      <div class="ol-seats" v-on:click="showCheckin(order.order_id, false)">
                        <div>
                          <b>{{ order.order_checkin_adult > 0 && parseInt(order.order_checkin_adult) !== parseInt(order.order_adult) ? `${order.order_checkin_adult} / ${order.order_adult}` : order.order_adult }}</b>
                          <i>Взрослые</i>
                        </div>
                        <div>
                          <b>{{ order.order_checkin_children > 0 && parseInt(order.order_checkin_children) !== (parseInt(order.order_children_1) + parseInt(order.order_children_2)) ? `${order.order_checkin_children} / ${parseInt(order.order_children_1) + parseInt(order.order_children_2)}` : parseInt(order.order_children_1) + parseInt(order.order_children_2) }}</b>
                          <i>Дети</i>
                        </div>
                        <div>
                          <b>{{ order.order_checkin_children_free > 0 && parseInt(order.order_checkin_children_free) !== parseInt(order.order_children_free) ? `${order.order_checkin_children_free} / ${order.order_children_free}` : order.order_children_free }}</b>
                          <i>Дети без места</i>
                        </div>
                        <div>
                          <b v-if="order.transport_place_title && order.transport_place_title.length">
                           {{
                              order.order_tickets ?
                                  order.order_tickets.split(',').map(i => {
                                    return order.transport_place_title[parseInt(i)]
                                  }).sort((a,b) => { return a === 'сл' || b === 'сл' ? 1 : parseInt(a) - parseInt(b)}).join(',') : ''
                            }}
                          </b>
                          <b v-else>
                           {{order.order_tickets.split(',').sort((a,b) => {return parseInt(a) - parseInt(b)}).join(',') }}
                          </b>
                          <i>Места</i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>


    <div class="window" v-if="checkin.new">
      <div class="window-header">
        <h1>
          <i class="back" v-on:click="back()"></i>
          Посадка
        </h1>
      </div>
      <div class="window-form">

        <div class="steps" :data-step="checkinStep" :data-title="(checkinStep === 2 && checkin && checkin.order && checkin.order.order_id ? 'Информация о билете №'+ this.checkin.order.order_id : checkinStepTitle[checkinStep])">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>

        <template v-if="checkinStep === 1">
          <Input name="order_id" text="Номер заказа" type="number" required="true" v-model="checkin.order_id" />
          <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        </template>

        <template v-if="checkinStep === 2">

          <template v-if="checkin.order && checkin.order.order_id">

            <div class="msg-error mb14" style="font-size: 24px;text-align: center;padding: 20px 8px" v-if="data.oldFlight && !data.oldFlight.find(i => i.flight_id === checkin.order.flight_id && i.transport_id === checkin.order.transport_id)">
              Посадка запрещена
            </div>

            <div class="input mb-0">
              <label class="input-placeholder">Дата и время отправления</label>
              <div>
                {{ convertDate( checkin.order.order_date_ot ) }}
              </div>
            </div>

            <div class="input mb-0">
              <label class="input-placeholder">Места</label>
              <div v-if="checkin.order.transport_place_title && checkin.order.transport_place_title.length">
                {{ (checkin.order.order_transfer || checkin.order.order_tickets).split(',').map(i => {return checkin.order.transport_place_title[parseInt(i)]}).sort((a,b) => { return a === 'сл' || b === 'сл' ? 1 : parseInt(a) - parseInt(b) }).join(',') }}
              </div>
              <div v-else>
                {{checkin.order.order_transfer ? checkin.order.order_transfer.split(',').sort((a,b) => { return a === 'сл' || b === 'сл' ? 1 : parseInt(a) - parseInt(b)}).join(',') : checkin.order.order_tickets.split(',').sort((a,b) => { return a === 'сл' || b === 'сл' ? 1 : parseInt(a) - parseInt(b)}).join(',') }}
              </div>
            </div>

            <div class="input mb-0">
              <label class="input-placeholder">Экскурсия</label>
              <div>
                {{ checkin.order.order_title }}
              </div>
            </div>

            <div class="input mb-0">
              <label class="input-placeholder">Автобус</label>
              <div>
                {{ checkin.order.transport_title }}
              </div>
            </div>

            <div class="input">
              <label class="input-placeholder">Клиент</label>
              <div>
                {{ checkin.order.order_client_title }} - <a :href="`tel:${checkin.order.order_client_phone}`">{{ formatPhone(checkin.order.order_client_phone) }}</a>
              </div>
            </div>

            <div class="input mb-0" v-if="checkin.order.order_seller_data && checkin.order.order_seller_data.length">
              <label class="input-placeholder">Продавец</label>
              <div>
                {{ checkin.order.order_seller_data[0].label }} - <a :href="`tel:${checkin.order.order_seller_phone}`">{{ formatPhone(checkin.order.order_seller_phone) }}</a>
              </div>
            </div>

             <div class="input-group">
                <Input type="number" name="order_adult" text="Взрослые" required="true" readonly min="0" :max="checkin.order.order_adult" v-model.number.trim="checkin.order.order_adult" />
                <div class="d-flex flex-align-center count-btn">
                  <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="checkin.order.order_adult > 0 ? checkin.order.order_adult-- : false">-</a>
                </div>
              </div>

              <div class="input-group">
                <Input type="number" name="order_children" text="Дети" required="true" readonly min="0" :max="checkin.order.order_children" v-model.number.trim="checkin.order.order_children" />
                <div class="d-flex flex-align-center count-btn">
                  <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="checkin.order.order_children > 0 ? checkin.order.order_children-- : false">-</a>
                </div>
              </div>

              <div class="input-group">
                <Input type="number" name="order_children_free" text="Дети без места" readonly required="true" v-model.number.trim="checkin.order.order_children_free" />
                <div class="d-flex flex-align-center count-btn">
                  <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="checkin.order.order_children_free > 0 ? checkin.order.order_children_free-- : false">-</a>
                  <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="checkin.order.order_children_free++">+</a>
                </div>
              </div>

          </template>
          <template v-else>
            Заказ не найден:(
          </template>

        </template>
      </div>

      <div class="form-submit">
        <button :disabled="checkinStep === 2 && checkin.order && checkin.order.flight_id && data.oldFlight && !data.oldFlight.find(i => i.flight_id === checkin.order.flight_id && i.transport_id === checkin.order.transport_id)" class="btn btn-primary" v-on:click.prevent="checkinStep === 2 ? save() : getOrder()">{{checkinStep === 2 ? 'Сели' : 'Продолжить'}}</button>
        <a class="btn btn-secondary" v-on:click.prevent="checkin.new = false;checkinStep = 1">
          Отменить
        </a>
      </div>
    </div>

    <Nav />

  </div>
</template>

<script>

import mixins from '../../../helpers/mixins.js';
import Input from "@/components/Input";
import Nav from "@/pages/guide/nav";
import User from '../../../user';
import Ajax from "@/helpers/ajax";

import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  mixins: [mixins],

  components: {
    Nav,
    StreamBarcodeReader,
    Input
  },

  created() {
    this.loadFlight();
    this.emitter.off("updateScheduleReport")
  },

  mounted() {
    this.emitter.off("updateScheduleReport")
    this.emitter.on("updateScheduleReport", async () => {
      let flights = this.data.flight

      await this.loadFlight()

      flights.forEach(i => {
        this.loadFlightInfo(i.flight_id, true, i.sticky_flight)
      })
    })

  },

  data() {
    return {
      arh: false,
      showFlight: [],
      account_id: User.currentUser.account.account_id || 0,
      data: {
        oldFlight: null
      },

      checkin: {
        new: false,
        order_id: null
      },

      activeFlight: null,

      checkinStep: 1,

      checkinStepTitle: [
        '',
        'Поиск билета',
        'Информация о билете',
      ],
      error: null,
      report: null
    }
  },

  methods: {

    back() {
      if (this.checkinStep === 1) {
        this.checkin.new = false
      } else {
        this.checkinStep--
      }
    },

    async loadFlight() {
      this.data.landing = null;
      let urls = {
        'flight': `/guide/flight`,
      };

      let res = await Ajax.getBulk(urls);

      let newFlight = []
      let oldFlight = [];

           if (res.flight) {

             res.flight.map((a, o) => {

               oldFlight.push({
                 flight_id: a.flight_id,
                 transport_id: a.transport_id
               })

               newFlight.push(a)
               newFlight[o].cnt_total_seats_save = a.cnt_total_seats
               newFlight[o].sticky_flight = []

               res.flight.map(b => {
                 if (a.schedule_date === b.schedule_date && a.flight_id !== b.flight_id && !newFlight.find(i => i.flight_id === b.flight_id) && !newFlight.find(i => i.sticky_flight.find(t => t.flight_id === b.flight_id))) {
                   newFlight[o].sticky_flight.push(b)
                   newFlight[o].cnt_checkin_people = newFlight[o].cnt_checkin_people + b.cnt_checkin_people
                   newFlight[o].cnt_total_people = newFlight[o].cnt_total_people + b.cnt_total_people

                   newFlight[o].cnt_checkin_seats = newFlight[o].cnt_checkin_seats + b.cnt_checkin_seats
                   newFlight[o].cnt_total_seats = newFlight[o].cnt_total_seats + b.cnt_total_seats
                 }
               })
             })

             newFlight.forEach(i => {
              if (i.sticky_flight.length) {
                i.sticky_flight.forEach(a => {
                  newFlight.forEach((b, k) => {
                    if (a.flight_id === b.flight_id) {
                      newFlight.splice(k, 1)
                    }
                  })
                })
              }
             })
           }


        res.oldFlight = oldFlight

        res.flight = newFlight;

      this.data = res
    },

    async loadFlightInfo(flight_id, reload = false, sticky_flight = []) {

      let flight_ids = flight_id;

      if (this.showFlight[flight_id] && !reload) {
        this.showFlight[flight_id].show = !this.showFlight[flight_id].show;
        return;
      }

      if (sticky_flight.length) {
        flight_ids = flight_ids + ',' + sticky_flight.map(i => i = i.flight_id).join(',')
      }

      let tmp = this.showFlight[flight_id]
      let res = await Ajax.get(`/guide/flight/${flight_ids}`);

      if (reload && tmp && tmp.response) {
        res.show = tmp && tmp.show ? tmp.show : false;
        tmp.response.forEach((i, k) => {
          res.response[k].show = tmp.response[k].show
        })

      } else {
        res.show = true;
      }

      this.showFlight[flight_id] = res
    },

    async getOrder() {
      if (this.checkin.order_id > 1000) {
        this.checkinStep++

        let res = await Ajax.get(`/order/${this.checkin.order_id}`);

        this.checkin.order = res.response;
        this.checkin.order.order_children = parseInt(this.checkin.order.order_children_1) + parseInt(this.checkin.order.order_children_2)
      } else {
        alert('Введите корректный номер!')
      }
    },

    async save() {
      let list = []

      let orderData = {
        order_id: this.checkin.order_id,
        order_client_title: this.checkin.order.order_client_title,
        order_client_phone: this.checkin.order.order_client_phone,
        order_checkin_adult: this.checkin.order.order_adult,
        order_checkin_children: this.checkin.order.order_children,
        order_checkin_children_free: this.checkin.order.order_children_free
      }

      list.push(Ajax.post(`/order/save_staff/${this.checkin.order_id}`, orderData));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
      } else {
        this.$toast.success({content: `Информация сохранена`});
        this.checkinStep = 1;


        this.data.flight.forEach(v => {
          if (this.showFlight[v.flight_id]) {
            this.showFlight[v.flight_id].response.forEach(place => {
              place.order_data.map(order => {
                if (this.checkin.order_id === order.order_id) {
                  order.order_checkin_adult = orderData.order_checkin_adult
                  order.order_checkin_children = orderData.order_checkin_children
                  order.order_checkin_children_free = orderData.order_checkin_children_free
                }
              })
            })
          }
        })

        this.checkin = {
          new: false,
          order: null,
          order_id: null
        }
      }
    },

    onDecode(barcode) {
      this.showCheckin(barcode)
    },

    onLoaded() {
      console.log('onLoaded')
    },

    showCheckin(order_id) {
      this.checkinStep = 1;

      this.checkin = {
        new: true,
        order: null,
        order_id: order_id
      }
      this.getOrder()
    }

  }
}
</script>
