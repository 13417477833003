<template>
  <div>

    <h1>Касса</h1>

    <Report ref="report" url="collection/report" :def-params="{order: {field: 'collection_id', order: 'desc'}, filters: {'t.account_id': 'eq|' + cash.account_id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div v-else>

        <div class="summary" v-if="report">
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_current_cash)}}</b>
            <i>Наличных в кассе</i>
            <button class="btn btn-primary mb14 mt14" v-on:click="sendMoney = !sendMoney;sendAward = false">Сдать кассу</button>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_salary)}}</b>
            <i>Доступно ЗП</i>
            <button class="btn btn-primary mb14 mt14" v-on:click="sendAward = !sendAward;sendMoney = false">Получить ЗП</button>
          </div>
        </div>

        <template v-if="sendMoney">
          <Input text="Сумма" type="number" name="amount" icon2="₽" v-model="cash.amount" />
          <template v-if="cash.amount > 100">
            <div class="mb14 mt14">
              Предъявите штрихкод инкассатору.
            </div>
            <div class="mb14">
              <Barcode
                  :value="cash.account_id + '-' + cash.amount/10/2"
                  :format="'CODE39'"
                  :text="cash.account_id + '-' + cash.amount/10/2"
                  :lineColor="'#000'"
                  :width="4"
                  :height="250"
                  :elementTag="'img'"
                  style="width: 100%"
              />
            </div>
            <button class="btn btn-primary mb14" v-on:click="cash.amount = 0;$refs.report.reload()">Инкассатор принял деньги</button>
          </template>
        </template>

        <template v-if="sendAward">
          <p>
            Введите сумму которую хотите получить, возьмите сумму из кассы и нажмите кнопку <b>сохранить</b>.
          </p>
          <Input text="Сумма" type="number" name="collection_amount" :max="report.summary.seller_summary.sum_salary" icon2="₽" v-model="award.collection_amount" />
          <template v-if="award.collection_amount > 0">
            <button class="btn btn-primary mb14" :disabled="report.summary.seller_summary.sum_salary < award.collection_amount" v-on:click="saveAward">Сохранить</button>
          </template>

        </template>


        <h3>Итого за весь период</h3>
        <div class="summary" v-if="report" >
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_cash)}}</b>
            <i>Наличный расчёт</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_card)}}</b>
            <i>Оплата картой</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_online)}}</b>
            <i>Оплата онлайн</i>
          </div>
        </div>
        <div class="summary" v-if="report">
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_refund_cash)}}</b>
            <i>Возвраты наличных</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_refund_other)}}</b>
            <i>Остальные возвраты</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_total)}}</b>
            <i>Итого продаж</i>
          </div>
        </div>
        <div class="summary" v-if="report" style="flex-wrap: wrap">

          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_only_collection)}}</b>
            <i>Инкассация</i>
          </div>
          <div>
            <b>{{formatRUB(report.summary.seller_summary.sum_collection_award)}}</b>
            <i>Получено ЗП</i>
          </div>
        </div>
        <h3>История изменений</h3>

        <div v-if="report" class="card-report">
          <div v-if="report.rows_count === 0">
            Не найдено.
          </div>

          <template v-else>
            <div
                class="item"
                v-for="(row, index) in report.rows"
                v-bind:key="index"
            >
              <h3 class="d-flex flex-space_between">
                {{row.collector_id ? 'Инкассация' : 'ЗП'}} -
                {{convertDate(row.collection_date)}}
                <span>
                  -{{formatRUB(row.collection_amount)}}
                </span>
              </h3>

              <div v-if="row.collector_id">
                Забрал: <b>{{row.collector_title}}</b>
              </div>

            </div>

          </template>
        </div>

        <Pager />
      </div>
    </Report>


    <Nav />
  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Input from "@/components/Input";
import Barcode from "@/components/Barcode";

import Nav from "@/pages/cashier/nav";
import User from "@/user";
import Report from "@/components/report/Report";
import Ajax from "@/helpers/ajax";

export default {
  mixins: [mixins],

  components: {
    Input,
    Report,
    Barcode,
    Nav
  },

  created() {

  },

  data() {
    return {
      sendAward: false,
      error: null,
      report: null,
      availableBalance: 0,
      sendMoney: false,
      cash: {
        amount: 0,
        account_id: User.currentUser.account.account_id || 0
      },
      award: {
        award: true,
        collection_amount: 0,
        account_id: User.currentUser.account.account_id || 0
      }

    }
  },

  methods: {

    async saveAward() {

      let list = []

      list.push(Ajax.post(`/collection/save/0`, this.award));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
      } else {
        this.$toast.success({content: `Информация сохранена`});

        this.sendAward = false;
        this.award.amount = 0;
        this.$refs.report.reload()
      }

    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

  }
}
</script>
