<template>
  <div>
    <h1>Посадки</h1>

    <div>

      <PeriodPicker
          name="filterDate"
          add-time="false"
          text="Дата"
          @apply="(res) => {filterDate = res}"
          v-model="filterDate"
      />
    </div>
    <div class="card-report" v-if="data.landing">
      <div class="item d-flex" v-for="(item, index) in data.landing" v-bind:key="index">
        <div class="left">
          <h3>{{item.order_place_time}}</h3>
        </div>
        <div class="right f-auto">
          <div v-for="(ex, index) in item.exData" v-bind:key="index">

            <h3 :class="{'pt-20': index > 0}" v-on:click="show === ex.flight_id ? show = null : show = ex.flight_id">
              <div>
                {{ex.excursion_title}}
              </div>
              <div>
                {{ex.count}} {{declination(ex.count, ['человек', 'человека', 'человек'])}}
              </div>
            </h3>

            <div class="places">
             {{ex.places.join(', ')}}
            </div>

            <div class="orders" v-show="show === ex.flight_id">
              <div class="orders_item" v-for="(order, index) in ex.tickets" v-bind:key="index">
                <input type="checkbox" v-model="order.order_landing_on" v-on:change="landingOn(order.order_landing_on, order.order_id)">
                <h4>
                  {{ order.transport_title || 'Автобус не назначен'}}
                </h4>
                <h4>
                  {{order.client_title}}
                  (
                    {{order.order_adult + order.order_children_1 + order.order_children_2 + order.order_children_free}}
                    {{declination(order.order_adult + order.order_children_1 + order.order_children_2 + order.order_children_free, ['человек', 'человека', 'человек'])}}
                  )
                </h4>
                <a :href="`tel:${order.client_phone}`">
                  {{formatPhone(order.client_phone)}}
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <h3 v-else>У вас нет посадок на {{convertDate(filterDate, 'DD MMMM YYYY года.')}}</h3>

    <Nav />
  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Nav from "@/pages/cashier/nav";
import PeriodPicker from "@/components/PeriodPicker";
import moment from "moment/moment";
export default {
  mixins: [mixins],

  components: {
    Nav,
    PeriodPicker

  },

  created() {
    this.loadLanding();
  },

  watch: {
    filterDate() {
      this.loadLanding()
    }
  },

  data() {
    return {
      filterDate: moment().format('YYYY-MM-DD'),
      data: {

      },

      show: null,
      error: null,
      report: null
    }
  },

  methods: {

    async landingOn(value, order_id)  {
      if (order_id > 10) {
        let list = [];
        list.push(Ajax.post(`/order/save_landing/${order_id}`, {order_landing_on: value ? 1 : 0}));

        let [orderResult] = await Promise.all(list);

        if (orderResult.error) {
          this.$toast.error({content: `Ошибка :(`});
          this.setError(orderResult.error);
        }

        if (orderResult.response) {
          console.log('- order_landing_on = saved')
        }
      }
    },

    async loadLanding() {

      this.data.landing = null;
      let urls = {
        'landing': `/profile/landing?date=${this.filterDate}`,
      };

      let res = await Ajax.getBulk(urls);


      if (res.landing.length) {
        res.landing.forEach((i, k) => {
          let exData = []


          i.landing_data.forEach((e) => {
            let flight_id = e.flight_id;

            if (!exData.find(t => t.flight_id === e.flight_id)) {
              let exDataItem = {
                flight_id: e.flight_id,
                excursion_title: e.order_title,
                count: 0,
                order_id: e.order_id,
                places: [],
                tickets: []
              }

              i.landing_data.forEach((e) => {
                if (e.flight_id === flight_id) {
                  if (exDataItem.places.indexOf(e.place_title) === -1) {
                    exDataItem.places.push(e.place_title)
                  }
                  e.order_landing_on = e.order_landing_on === 1
                  exDataItem.tickets.push(e)

                  exDataItem.count = exDataItem.count + e.order_adult + e.order_children_1 + e.order_children_2 + e.order_children_free
                }
              })
              exData.push(exDataItem)
            }
            res.landing[k].exData = exData
          })
        })
        this.data = res
      }
    },
  }
}
</script>
